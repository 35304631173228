import Immutable from "immutable"
import _ from "lodash"
import * as actions from "../actions/actionTypes"

const initialState = Immutable.Map({
  accepted: Immutable.List(),
  rejected: Immutable.List(),
  notImportant: Immutable.List(),
})

const summaryOutcomeGroups = (state = initialState, action) => {
  switch (action.type) {
    case actions.GROUP_OUTCOMES:
      return initialState.merge(
        Immutable.fromJS(_.groupBy(action.outcomes, "status"))
      )

    case actions.MOVE_OUTCOME:
      const { fromGroup, fromIndex, toGroup, toIndex } = action
      const outcome = state.getIn([fromGroup, fromIndex])

      return state
        .deleteIn([fromGroup, fromIndex])
        .update(toGroup, toGroupOutcomes =>
          toGroupOutcomes.insert(toIndex, outcome)
        )

    default:
      return state
  }
}

export default summaryOutcomeGroups
